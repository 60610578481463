import { render } from "./LicenseAgreement.vue?vue&type=template&id=344307fe&scoped=true"
import script from "./LicenseAgreement.vue?vue&type=script&lang=js"
export * from "./LicenseAgreement.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-344307fe"
/* hot reload */
if (module.hot) {
  script.__hmrId = "344307fe"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('344307fe', script)) {
    api.reload('344307fe', script)
  }
  
  module.hot.accept("./LicenseAgreement.vue?vue&type=template&id=344307fe&scoped=true", () => {
    api.rerender('344307fe', render)
  })

}

script.__file = "src/components/terms/LicenseAgreement.vue"

export default script